package su.exbot.forms.containers.panel.tabs.questions.components

import emotion.react.css
import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.textarea
import react.useState
import su.exbot.forms.client
import su.exbot.forms.containers.panel.tabs.questions.interfaces.IQuestionComponent
import su.exbot.forms.elements.Icons
import su.exbot.forms.containers.panel.tabs.questions.model.QuestionEntity
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.None
import web.cssom.pct
import kotlin.js.json

val QuestionEditedComponent = FC<IQuestionComponent> {props ->
    val item = props.question
    val (questions, setQuestions) = props.useQuestions

    val (question, setQuestion) = useState(item.name)
    val (description, setDescription) = useState(item.text)
    val (answer, setAnswer) = useState(item.answer)

    div {
        className = ClassName("accordion-item")
        div {
            className = ClassName("accordion-collapse collapse show")
            div {
                className = ClassName("accordion-body")

                div {
                    span {
                        className = ClassName("fw-bold")
                        +"Вопрос №${item.id}: "
                    }
                    textarea {
                        css {
                            border = None.none
                            backgroundColor = Color("transparent")
                            color = Color("var(--bs-body-color)")
                            width = 100.pct
                            focus {
                                outline = None.none
                            }
                        }
                        placeholder = "Введите текст вопроса в данную строку"
                        value = question
                        onChange = {
                            setQuestion(it.target.value)
                        }
                    }
                }
                hr()
                div {
                    textarea {
                        className = ClassName("form-control")
                        placeholder = "Описание / Уточнение к вопросу"
                        value = description
                        onChange = {
                            setDescription(it.target.value)
                        }
                    }
                    textarea {
                        className = ClassName("form-control")
                        placeholder = "Подсказка / Ответ к вопросу (Видно в режиме просмотра)"
                        value = answer
                        onChange = {
                            setAnswer(it.target.value)
                        }
                    }
                }
                hr()
                div {
                    className = ClassName("d-grid gap-2")
                    button {
                        className = ClassName("btn btn-dark btn-sm")
                        Icons.sendFill()
                        if(item.id == 0) {
                            +" Добавить вопрос в базу"
                        } else {
                            +" Применить изменения"
                        }
                        onClick = {
                            val map = json(
                                "question" to question,
                                "description" to description,
                                "answer" to answer,
                            )
                            val axios = if(item.id == 0) {
                                client.post(
                                    url = "/backend/forms/${item.formId}/questions",
                                    data = map
                                )
                            } else {
                                client.put<dynamic>(
                                    url = "/backend/forms/${item.formId}/questions/${item.id}",
                                    data = map
                                )
                            }
                            axios.then { response ->
                                if(response.status == 200) {
                                    val data = response.data["item"].unsafeCast<QuestionEntity>()

                                    questions!!.map {
                                        if(it.id != item.id) it
                                        else data
                                    }.toTypedArray().apply {
                                        setQuestions(this)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}