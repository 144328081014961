package su.exbot.forms.routes.form.domain

import emotion.react.css
import react.FC
import react.dom.aria.ariaExpanded
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h5
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import react.router.useNavigate
import react.useState
import su.exbot.forms.client
import su.exbot.forms.containers.panel.tabs.userforms.UserFormsContainer
import su.exbot.forms.dataBsToggle
import su.exbot.forms.elements.Icons
import su.exbot.forms.getFormStatus
import su.exbot.forms.profiles.ProfileData
import su.exbot.forms.routes.comments.domain.CommentBoard
import su.exbot.forms.routes.form.props.ItemTableProps
import web.cssom.*
import web.location.location
import web.navigator.navigator
import web.window.WindowTarget
import kotlin.js.json

val CreateForm = FC<ItemTableProps> { props ->
    val (items, _) = props.contentItems

    val form = props.form?:return@FC
    var prefixState by useState(form.status)

    val navigate = useNavigate()

    div {
        className = ClassName("group")
        div {
            className = ClassName("d-flex align-items-center")
            div {
                val (color, _, _) = getFormStatus(prefixState)
                css {
                    borderRadius = 50.pct
                    width = 8.px
                    height = 8.px
                    backgroundColor = color
                    boxShadow = BoxShadow(0.px, 0.px, 8.px, 2.px, color)
                    marginRight = 10.px
                }
            }
            h5 {
                css {
                    margin = 0.px
                }
                +"${form.name} [ID: ${form.userFormId}]"
            }
        }
    }
    div {
        className = ClassName("position-relative")
        div {
            span {
                dataBsToggle = "dropdown"
                ariaExpanded = false
                css {
                    position = Position.absolute
                    left = 95.pct
                    top = 0.pct
                    cursor = Cursor.pointer
                }
                Icons.ThreeDots()
            }
            ul {
                className = ClassName("dropdown-menu")
                for(status in 0..3) {
                    val (color, _, actionName) = getFormStatus(status)
                    a {
                        className = ClassName("dropdown-item d-flex align-items-center")
                        div {
                            css {
                                borderRadius = 50.pct
                                width = 8.px
                                height = 8.px
                                backgroundColor = color
                                boxShadow = BoxShadow(0.px, 0.px, 8.px, 2.px, color)
                                marginRight = 10.px
                            }
                        }
                        +actionName
                        onClick = {
                            client.patch<dynamic>(
                                url = "/backend/user-forms/${form.userFormId}",
                                data = json("status" to status)
                            ).then {
                                if(it.status == 200) {
                                    val value = it.data["status"] as Int
                                    form.status = value
                                    prefixState = value

                                    UserFormsContainer.list?.firstOrNull { item ->
                                        item.token == form.token
                                    }?.status = value
                                }
                            }
                        }
                    }
                }
                hr {
                    className = ClassName("dropdown-divider")
                }
                a {
                    className = ClassName("dropdown-item")
                    i {
                        className = ClassName("bi bi-link")
                    }
                    +" Копировать ссылку"
                    onClick = {
                        navigator.clipboard.writeText("${location.origin}/request?id=${form.userFormId}")
                    }
                }
                a {
                    className = if(form.canDelete) {
                        ClassName("dropdown-item")
                    } else {
                        ClassName("dropdown-item disabled")
                    }
                    i {
                        className = ClassName("bi bi-trash text-danger")
                    }
                    +" Удалить форму"
                    onClick = {
                        client.delete<dynamic>("/backend/user-forms/${form.userFormId}").then {
                            if(it.status == 200) {
                                UserFormsContainer.list?.firstOrNull { item ->
                                    item.id == form.userFormId
                                }.apply {
                                    UserFormsContainer.list?.remove(this)
                                    navigate(-1)

                                }
                            }
                        }
                    }
                }
            }
        }

        val profile = ProfileData.getById(form.createdId)
        span {
            i {
                className = ClassName("bi bi-person-circle")
            }
            +" Отправитель: "
            if(profile != null) {
                a {
                    css(ClassName("fw-bold")) {
                        color = Color("var(--bs-body-color)")
                        textDecoration = None.none
                    }
                    target = WindowTarget._blank
                    href = "https://vk.com/id${profile.id}"
                    +profile.firstAndLastName
                }
            } else {
                div {
                    css(ClassName("placeholder")) {
                        width = 120.px
                    }
                }
                ProfileData.addQueue(form.createdId.toLong())
            }
        }
        span {
            i {
                className = ClassName("bi bi-router")
            }
            +" IP-адрес: "
            a {
                css(ClassName("fw-bold")) {
                    color = Color("var(--bs-body-color)")
                    textDecoration = None.none
                }
                target = WindowTarget._blank
                href = "https://check-host.net/ip-info?host=${form.IP}"
                +form.IP
            }
        }
        span {
            i {
                className = ClassName("bi bi-watch")
            }
            +" Время создания: "
            span {
                className = ClassName("fw-bold")
                +form.startTime
            }
        }
        span {
            i {
                className = ClassName("bi bi-stopwatch")
            }
            +" Время прохождения: "
            span {
                className = ClassName("fw-bold")
                +form.time
            }
        }
        br()
        span {
            className = ClassName("bi bi-calculator")
            +" Статистка по ответам:"
        }
        for(en in items!!.groupBy { it.status }) {
            span {
                className = ClassName("ms-3")
                val name = when(en.key) {
                    -1 -> "Не отвеченных"
                    0 -> "По умолчанию"
                    1 -> "Одобренных"
                    2 -> "Частично одобренных"
                    3 -> "Отказанных"
                    else -> "status = ${en.key}"
                }
                +" - $name: "
                span {
                    className = ClassName("fw-bold")
                    +(en.value.size).toString()
                }
            }
        }
        button {
            className = ClassName("btn btn-dark mt-3")
            Icons.caretLeftFill()
            +" Назад"
            onClick = {
                navigate(-1)
            }
        }
    }

    CommentBoard {
        this.userFormId = props.formUserId
        this.useComments = props.useComments
    }

    for(en in items!!.groupBy { it.group }) {
        CreateGroup {
            this.group = en.key
            this.items = en.value
            this.tableProps = props
        }
    }
}