package su.exbot.forms.routes.main.domain

import generated.snackBar
import js.objects.jso
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.decodeFromDynamic
import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import su.exbot.forms.client
import su.exbot.forms.elements.Icons
import su.exbot.forms.format
import su.exbot.forms.routes.main.IndexRoute
import su.exbot.forms.routes.main.model.Form
import su.exbot.forms.routes.main.props.ItemTableProps
import web.cssom.ClassName
import web.cssom.px

@OptIn(ExperimentalSerializationApi::class)
val createForm = FC<ItemTableProps> { props ->
    val (items, setItems) = props.contentItems
    val (form, setFormData) = props.formData
    for(en in items.groupBy { it.group }) {
        createGroup {
            this.group = en.key
            this.items = en.value
            this.contentItems = props.contentItems
            this.formData = form!!
        }
    }

    if(form == null) return@FC
    div {
        className = ClassName("control")
        button {
            className = ClassName("btn btn-dark")
            disabled = (form.page ?: 0) <= 1
            Icons.caretLeftFill()
            +" Пред. Страница"
            onClick = {
                IndexRoute.loadItemsPage(form, form.page!!.minus(1), props.contentItems)
            }
        }
        button {
            className = ClassName("btn btn-dark")
            if (form.page!! >= form.pages) {
                +"Отправить форму "
                Icons.sendFill()
                onClick = {
                    if (items.none { it.sent != true }) {
                        client.put<dynamic>("/backend/user-forms/${form.userFormId}/complete").then {
                            setFormData(format.decodeFromDynamic<Form>(it.data))
                            setItems(mutableListOf())
                        }
                    } else {
                        for (data in items) {
                            if (data.answer.isBlank()) {
                                snackBar(jso {
                                    status = "error"
                                    message = "Вы не отправили ответ к вопросу №${data.index}"
                                    width = 350.px
                                    fixed = true
                                })
                            }
                        }
                        snackBar(jso {
                            status = "info"
                            message = "Для отправки ответа, нажмите на кнопку со значком бумажного самолетика"
                            width = 370.px
                            fixed = true
                            timeout = 20000
                        })
                    }
                }
            } else {
                +"След. Страница "
                Icons.caretRightFill()
                onClick = {
                    if (items.none { it.sent != true }) {
                        IndexRoute.loadItemsPage(form, form.page!!.plus(1), props.contentItems)
                    } else {
                        for (data in items) {
                            if (data.answer.isBlank()) {
                                snackBar(jso {
                                    status = "error"
                                    message = "Вы не отправили ответ к вопросу №${data.index}"
                                    width = 370.px
                                    fixed = true
                                })
                            }
                        }
                        snackBar(jso {
                            status = "info"
                            message = "Для отправки ответа, нажмите на кнопку со значком бумажного самолетика"
                            width = 370.px
                            fixed = true
                            timeout = 20000
                        })
                    }
                }
            }
        }
    }
}