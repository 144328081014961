package su.exbot.forms.containers.panel.tabs.questions.components

import emotion.react.css
import react.FC
import react.dom.aria.ariaControls
import react.dom.aria.ariaExpanded
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.span
import su.exbot.forms.client
import su.exbot.forms.containers.panel.tabs.questions.interfaces.IQuestionComponent
import su.exbot.forms.functions.TextFormat
import su.exbot.forms.containers.panel.tabs.questions.model.QuestionEntity
import web.cssom.*

val QuestionComponent = FC<IQuestionComponent> {props ->
    val item = props.question
    val (questions, setQuestions) = props.useQuestions

    div {
        className = ClassName("accordion-item")
        h2 {
            className = ClassName("accordion-header")
            button {
                className = ClassName("accordion-button collapsed")
                asDynamic()["data-bs-toggle"] = "collapse"
                asDynamic()["data-bs-target"] = "#question-${item.id}"
                ariaExpanded = true
                ariaControls = "question-${item.id}"
                div {
                    if(item.id < 0) {
                        i {
                            css(ClassName("bi bi-trash3 mx-1")) {
                                color = Color("#df5858")
                            }
                        }
                    }
                    span {
                        val questionId = if(item.id > 0) item.id else -item.id
                        className = ClassName("fw-bold")
                        +"Вопрос №$questionId: "
                    }
                    span {
                        +item.name
                    }
                }
            }
        }
        div {
            className = ClassName("accordion-collapse collapse")
            id = "question-${item.id}"
            div {
                className = ClassName("accordion-body")
                if (!item.text.isNullOrBlank()) {
                    div {
                        css {
                            fontSize = 15.px
                            fontStyle = FontStyle.italic
                            marginBottom = 2.vh
                        }
                        TextFormat {
                            this.text = item.text!!
                        }
                    }
                }
                div {
                    input {
                        className = ClassName("form-control")
                        readOnly = true
                        value = item.answer
                        disabled = item.answer?.isBlank()
                    }
                }
                hr()
                div {
                    className = ClassName("d-grid gap-2")
                    button {
                        className = ClassName("btn btn-secondary btn-sm")
                        disabled = !item.canInteract || item.id < 0
                        +"Редактировать вопрос"
                        onClick = {
                            questions!!.map {
                                if(it.id == item.id) {
                                    it.editMode = !it.editMode
                                }
                                it
                            }.toTypedArray().apply {
                                setQuestions(this)
                            }
                        }
                    }
                    button {
                        disabled = !item.canInteract
                        if(item.id > 0) {
                            className = ClassName("btn btn-danger btn-sm")
                            +"Удалить вопрос"
                        } else {
                            className = ClassName("btn btn-success btn-sm")
                            +"Восстановить вопрос"
                        }
                        onClick = {
                            if(item.id > 0) {
                                client.delete<dynamic>(
                                    url = "/backend/forms/${item.formId}/questions/${item.id}"
                                ).then {
                                    if(it.status == 200) {
                                        val questionId = it.data["questionId"] as Int
                                        questions!!.map { question ->
                                            if(question.id == questionId) {
                                                question.id = -question.id
                                            }
                                            question
                                        }.toTypedArray().apply {
                                            setQuestions(this)
                                        }
                                    }
                                }
                            } else {
                                client.patch<dynamic>(
                                    url = "/backend/forms/${item.formId}/questions/${item.id}/restore"
                                ).then { response ->
                                    if(response.status == 200) {
                                        val question = response.data["item"].unsafeCast<QuestionEntity>()

                                        questions!!.map {
                                            if(it.id != -question.id) it
                                            else question
                                        }.toTypedArray().apply {
                                            setQuestions(this)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}