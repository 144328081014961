package su.exbot.forms.routes.main.model

import kotlinx.serialization.Serializable
import su.exbot.forms.profiles.model.GroupData
import su.exbot.forms.profiles.model.UserData

@OptIn(ExperimentalJsExport::class)
@JsExport
@Serializable
data class Form(
    val formId:Int,
    val name:String,
    var page:Int? = null,
    val pages: Int = 0,
    val status: Int,
    val userFormId: Int,
    val profile: UserData? = null,
    val group: GroupData? = null,
)