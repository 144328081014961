package su.exbot.forms.enums

import react.FC
import react.Props
import su.exbot.forms.containers.panel.tabs.administrators.AdminsContainer
import su.exbot.forms.containers.panel.tabs.configuring.ConfiguringContainer
import su.exbot.forms.containers.panel.tabs.questions.QuestionsContainer
import su.exbot.forms.containers.panel.tabs.userforms.UserFormsContainer
import su.exbot.forms.model.enums.Permissions

enum class TabType(val title: String, val item: FC<Props>, val path: String, val permission: Permissions) {
    LIST_OF_FORMS("Заявки пользователей", UserFormsContainer.index, "user-forms", Permissions.ACCESS_TO_REQUESTS),
    LIST_OF_ADMINISTRATORS("Администраторы", AdminsContainer, "admin-list", Permissions.ACCESS_TO_ADMINISTRATORS),
    //CONFIGURING_FORMS("Настройка форм", ConfiguringContainer, "configuring-forms", Permissions.ACCESS_MODIFY_FORM),
    CONFIGURING_FORMS("Настройка форм", ConfiguringContainer, "configuring-forms", Permissions.ACCESS_MODIFY_FORM),
    LIST_OF_QUESTIONS("Список вопросов", QuestionsContainer, "questions", Permissions.ACCESS_TO_QUESTIONS),
}