package su.exbot.forms.routes.comments.domain

import generated.AxiosResponse
import react.FC
import react.dom.aria.AriaRole
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.textarea
import react.useState
import su.exbot.forms.client
import su.exbot.forms.routes.comments.model.CommentEntity
import su.exbot.forms.routes.comments.props.CommentProps
import web.cssom.ClassName
import web.html.InputType
import kotlin.js.json

val CommentEdited = FC<CommentProps> { props ->
    val item = props.item
    var textState by useState(item.text)
    var checkState by useState(item.type == 1)
    val (comments, setComments) = props.board.useComments

    textarea {
        className = ClassName("form-control")
        placeholder = "Написать комментарий..."
        value = textState
        onChange = {
            textState = it.currentTarget.value
        }
    }
    div {
        className = ClassName("form-check form-switch")
        input {
            className = ClassName("form-check-input")
            id = "pin-comment-to-user"
            type = InputType.checkbox
            role = AriaRole.switch
            checked = checkState
            onChange = {
                checkState = it.target.checked
            }
        }
        label {
            className = ClassName("form-check-label")
            htmlFor = "pin-comment-to-user"
            +"Закрепить комментарий за пользователем"
        }
    }
    button {
        className = ClassName("btn btn-dark")
        if(item.id > 0) {
            +"Сохранить комментарий"
        } else {
            +"Опубликовать комментарий"
        }
        onClick = {
            val type = if(checkState) 1 else 0
            val userFormId = props.board.userFormId

            val onSuccess: (AxiosResponse<dynamic>) -> Unit = { response ->
                if(response.status == 200) {
                    val comment = response.data["item"].unsafeCast<CommentEntity>()

                    val rows = if(item.id >= 0) {
                        comments.map { row ->
                            if(item.id == row.id) {
                                comment
                            } else row
                        }
                    } else {
                        comments.filter { row ->
                            row.id != item.id
                        } + comment
                    }
                    setComments(rows.toTypedArray())
                }
            }

            if(item.id < 0) {
                client.post<dynamic>(
                    url = "/backend/user-forms/${userFormId}/comments",
                    data = json(
                        "text" to textState,
                        "type" to type,
                    )
                ).then(onSuccess)
            } else {
                client.put<dynamic>(
                    url = "/backend/user-forms/${userFormId}/comments/${item.id}",
                    data = json(
                        "text" to textState,
                        "type" to type,
                    )
                ).then(onSuccess)
            }
        }
    }
}