package su.exbot.forms.functions

import generated.Snackbar
import js.objects.jso
import js.uri.encodeURIComponent
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.get
import org.w3c.dom.set
import org.w3c.dom.url.URL
import su.exbot.forms.client
import su.exbot.forms.versionApiVK
import su.exbot.forms.versionApplication
import web.history.history
import web.location.location

val current_url = location.origin+location.pathname+encodeURIComponent(location.search)

fun authorize(function: (String, String?) -> Unit) {
    var link:String? = null
    val url = URL(location.href)
    val params = url.searchParams
    if(params.get("code") != null) {
        link = "/form/auth?code=${params.get("code")}&redirect_uri=${localStorage["redirect_uri"].toString()}"
        params.delete("code")
        history.pushState(null, "", url.href)
    } else {
        val panelToken = localStorage["panel_token"]
        if(panelToken.isNullOrBlank()) {
            val clientId = 7910314
            val version = versionApiVK
            val display = "page"

            val map = mutableMapOf<String, Any>()
            map["client_id"] = clientId
            map["display"] = display
            map["redirect_uri"] = current_url
            map["response_type"] = "code"
            map["version"] = version

            localStorage["redirect_uri"] = current_url

            val list = map.toList().map { "${it.first}=${it.second}" }
            window.location.href = "https://oauth.vk.com/authorize?${list.joinToString("&")}"
        } else {
            link = "/form/auth?panel_token=$panelToken"
        }
    }
    if(link != null) {
        client.get<dynamic>(link, jso {
            timeout = 5 * 1000
            validateStatus = { status ->
                status == 200
            }
        }).then {
            if(it.status == 200) {
                val type = it.data["type"].toString()
                if(type == "authorization") {
                    localStorage["panel_token"] = it.data["panel_token"].toString()
                    localStorage["name"] = it.data["name"].toString()
                    localStorage["photo"] = it.data["photo"].toString()
                    localStorage["user_id"] = it.data["user_id"].toString()

                    val level = it.data["level"]
                    if(level != null) {
                        localStorage["level"] = level.toString()
                        localStorage["server_id"] = it.data["server_id"].toString()
                    }
                    val version = it.data["version_application"].toString().toInt()
                    if(version > versionApplication) {
                        Snackbar("warning", """
                            Версия отличается от версии на сервере.<br>
                            Нажмите Ctrl + F5 для обновления кэша.
                        """.trimIndent())
                    }
                }
                if(type == "refresh") {
                    val theme = localStorage["theme"]
                    val host = localStorage["host"]
                    localStorage.clear()

                    if(theme != null)
                        localStorage["theme"] = theme
                    if(host != null)
                        localStorage["host"] = host

                    window.location.href = window.location.href
                }
                function(type, null)
            }
        }.catch {
            val theme = localStorage["theme"]
            val host = localStorage["host"]

            localStorage.clear()

            if(theme != null)
                localStorage["theme"] = theme
            if(host != null)
                localStorage["host"] = host
            function("not_authorized", "Не удалось авторизоваться")
        }
    }
}