package su.exbot.forms.routes.comments.domain

import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import su.exbot.forms.routes.comments.model.CommentEntity
import su.exbot.forms.routes.comments.props.BoardProps
import web.cssom.ClassName

val CommentBoard = FC<BoardProps> {props ->
    val (comments, setComments) = props.useComments
    
    div {
        if(comments.isEmpty()) {
            span {
                className = ClassName("text-center fw-lighter p-3")
                +"Комментарии к данной форме отсутствуют"
            }
        } else {
            comments.forEach {
                if(it.edited) {
                    CommentEdited {
                        this.item = it
                        this.board = props
                    }
                } else {
                    DisplayComment {
                        this.item = it
                        this.board = props
                    }
                }
            }
        }
        if(comments.none { it.edited }) {
            button {
                className = ClassName("btn btn-dark")
                +"Написать комментарий"
                onClick = {
                    val comment = CommentEntity(
                        id = -1,
                        fromId = 0,
                        dateAtHuman = "",
                        text = "",
                        type = 0,
                        canDelete = true,
                        canEdit = true,
                        edited = true
                    )
                    setComments(comments + comment)
                }
            }
        }
    }
}