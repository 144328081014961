package su.exbot.forms.containers.panel.tabs.configuring

import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h5
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.input
import react.router.useParams
import react.useEffectOnce
import react.useState
import su.exbot.forms.client
import su.exbot.forms.containers.panel.arrayForms
import su.exbot.forms.containers.panel.index.model.FormEntity
import su.exbot.forms.containers.panel.tabs.configuring.components.FieldRow
import su.exbot.forms.containers.panel.tabs.configuring.model.FieldEntity
import web.cssom.ClassName
import kotlin.js.json

val ConfiguringContainer = FC {
    val formId = useParams()["formId"] ?: return@FC
    div {
        className = ClassName("row")

        val form = arrayForms.first { it.id == formId.toInt() }
        val (editMode, setEditMode) = useState(false)
        val (title, setTitle) = useState(form.name)
        val (fields, setFields) = useState<List<FieldEntity>>(listOf())

        useEffectOnce {
            setFields(listOf(
                FieldEntity(1, "Test", listOf(2), 2, 1),
                FieldEntity(2, "Test2", listOf(2), 2, 2),
                FieldEntity(3, "Test3", listOf(2), 2, 2),
            ))
        }

        if(editMode) {
            div {
                className = ClassName("input-group")
                input {
                    className = ClassName("form-control")
                    value = title
                    onChange = {
                        setTitle(it.target.value)
                    }
                }
                button {
                    className = ClassName("btn btn-dark")
                    +"Сохранить"
                    onClick = {
                        client.put<dynamic>(
                            url = "/backend/forms/$formId",
                            data = json("name" to title)
                        ).then {
                            if(it.status == 200) {
                                val item = it.data["item"].unsafeCast<FormEntity>()
                                if(form.id == item.id) {
                                    form.name = item.name
                                }
                                setEditMode(false)
                            }
                        }
                    }
                }
            }
        } else {
            h5 {
                className = ClassName("text-center")
                +title
                onClick = {
                    setEditMode(true)
                }
            }
            hr()
        }
        fields.forEach {
            FieldRow {
                this.entity = it
            }
        }
    }
}