package su.exbot.forms.containers.panel.tabs.userforms

import emotion.react.css
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.events.Event
import org.w3c.dom.get
import org.w3c.dom.url.URL
import react.FC
import react.StateInstance
import react.dom.aria.ariaExpanded
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.ul
import react.router.dom.Form
import react.router.useParams
import react.useEffect
import react.useState
import su.exbot.forms.chooseWordForm
import su.exbot.forms.client
import su.exbot.forms.containers.panel.tabs.userforms.components.UserFormsTable
import su.exbot.forms.dataBsToggle
import su.exbot.forms.model.enums.FormStatus
import su.exbot.forms.profiles.ProfileData
import su.exbot.forms.projectName
import su.exbot.forms.routes.list.model.Item
import web.cssom.*
import web.html.InputType
import kotlin.js.Promise

object UserFormsContainer {

    private const val TITLE = "Список заявок"

    var list: MutableList<Item>? = null
    private var searchType = ""
    private var searchQuery = ""
    private var searchStatutes = listOf(1, 2, 3)

    private var offset = 0
    private const val COUNT = 100

    val index = FC {
        document.title = "$projectName | $TITLE"

        val url = URL(window.location.href)
        val params = url.searchParams
        val formId = useParams()["formId"] ?: return@FC

        /*useEffect(formId) {
            println("formId = $formId")
        }*/

        if(params.get("type") != null)
            searchType = params.get("type")!!
        if(params.get("query") != null)
            searchQuery = params.get("query")!!

        val serverId = localStorage["server_id"]?.toIntOrNull()
        if(serverId != null) {
            if(searchType.isBlank() && searchQuery.isBlank() && serverId > 0) {
                searchType = "utm-search"
                searchQuery = if(serverId < 10) "0$serverId" else serverId.toString()
            } else if(searchType.isBlank()) searchType = "vk-search"
        }

        val (useItems, isLoading, callUpdateData) = useData(formId.toInt())
        val (items, setItems) = useItems
        val useProfiles = useState(ProfileData.list.hashCode())
        val (statutes, setStatuses) = useState(searchStatutes)

        useEffect {
            if(ProfileData.queue.isNotEmpty()) {
                ProfileData.update(useProfiles)
            }
        }

        Form {
            className = ClassName("row justify-content-center")
            div {
                className = ClassName("col-md-auto my-1 dropdown")
                label {
                    +"Отображать статусы"
                }
                input {
                    className = ClassName("form-select dropdown-toggle")
                    type = InputType.text
                    readOnly = true
                    dataBsToggle = "dropdown"
                    ariaExpanded = false
                    value = " – Выбрано ${statutes.size} ${chooseWordForm("пункт", "", "а", "ов", statutes.size)}"
                }
                ul {
                    className = ClassName("dropdown-menu")
                    FormStatus.entries.forEach { status ->
                        a {
                            className = ClassName("dropdown-item d-flex align-items-center")
                            div {
                                css {
                                    borderRadius = 50.pct
                                    width = 8.px
                                    height = 8.px
                                    if(statutes.contains(status.ordinal)) {
                                        backgroundColor = Color(status.color)
                                    }
                                    boxShadow = BoxShadow(0.px, 0.px, 8.px, 2.px, Color(status.color))
                                    marginRight = 10.px
                                }
                            }
                            +status.title
                            onClick = {
                                val point = status.ordinal
                                if(searchStatutes.contains(point)) {
                                    searchStatutes -= point
                                } else {
                                    searchStatutes += point
                                }
                                setStatuses(searchStatutes)
                            }
                        }
                    }
                }
            }
            div {
                className = ClassName("col-md-auto my-1")
                label {
                    +"Тип поиска"
                }
                select {
                    className = ClassName("form-select")
                    name = "type"
                    defaultValue = searchType
                    option {
                        value = "vk-search"
                        +" – По странице ВК"
                    }
                    option {
                        value = "token-search"
                        +" – По ключу формы"
                    }
                    option {
                        value = "utm-search"
                        +" – По тэгу формы"
                    }
                    option {
                        value = "answer-search"
                        +" – По тексту ответов"
                    }
                    option {
                        value = "ip-search"
                        +" – По IP-адресу"
                    }
                    onChange = {
                        searchType = it.currentTarget.value
                    }
                }
            }
            div {
                className = ClassName("col-md-auto my-1")
                label {
                    +"Строка поиска"
                }
                div {
                    className = ClassName("input-group")
                    input {
                        className = ClassName("form-control")
                        name = "query"
                        placeholder = "Введите данные..."
                        defaultValue = searchQuery
                        onChange = {
                            searchQuery = it.currentTarget.value
                        }
                    }
                    button {
                        className = ClassName("btn btn-dark")
                        disabled = isLoading
                        +"Искать"
                    }
                }
            }
            onSubmit = {
                setItems(mutableListOf())
                offset = 0

                callUpdateData()
            }
        }

        if(!list.isNullOrEmpty()) {
            UserFormsTable {
                this.nextOffset = offset
                this.items = items
                this.loading = isLoading
                this.functionUpdate = callUpdateData
                this.statutes = statutes
            }
        }

        val handleScroll: (Event) -> Unit = {
            val scrollableHeight = document.documentElement?.scrollHeight?.toDouble()
            val scrollTop = window.pageYOffset + window.innerHeight

            if (scrollTop >= scrollableHeight!! && offset > 0) {
                callUpdateData()
            }
        }
        useEffect {
            window.addEventListener("scroll", handleScroll)
            this.cleanup {
                window.removeEventListener("scroll", handleScroll)
            }
        }
    }

    private fun useData(formId: Int): Triple<StateInstance<MutableList<Item>?>, Boolean, () -> Promise<Unit>> {
        val useUserForms = useState(list)
        val (userForms, setUserForms) = useUserForms
        val loadingState = useState(false)
        val (isLoading, setLoading) = loadingState

        val callLoadData = { ->
            setLoading(true)

            client.get<dynamic>(
                url = "backend/user-forms?" +
                        "query=$searchQuery&" +
                        "type=$searchType&" +
                        "offset=$offset&" +
                        "count=$COUNT&" +
                        "formId=$formId"
            ).then {
                if(it.status == 200) {
                    list = mutableListOf()

                    if(offset > 0 && userForms != null) {
                        list!!.addAll(userForms)
                    }
                    for(row in it.data["items"]) {
                        list!!.add(row.unsafeCast<Item>())
                    }
                    setUserForms(list)

                    offset = it.data["nextOffset"] as? Int?:-1
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }
        }
        useEffect(formId) {
            if(list == null) callLoadData()
        }
        return Triple(useUserForms, isLoading, callLoadData)
    }

    fun reset() {
        list = null
        offset = 0
    }
}