package ru.codeoff.bots.sdk.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

@Serializable
data class VkApiResponse(
    @SerialName("response")
    val response: JsonElement? = null,
    
    @SerialName("error")
    val error: VkApiError? = null
)

@Serializable
data class VkApiError(
    @SerialName("error_code")
    val code: Int,

    @SerialName("error_msg")
    val message: String,
)