package su.exbot.forms.routes.form.model

@OptIn(ExperimentalJsExport::class)
@JsExport
data class Form(
    val formId: Int,
    val name: String,
    val createdId: Int,
    val IP: String,
    val time: String,
    val token: String,
    var status: Int,
    val userFormId: Int,
    val startTime: String,
    val canDelete: Boolean
)

fun Form(it: Form): Form {
    return Form(it.formId, it.name, it.createdId, it.IP, it.time, it.token, it.status, it.userFormId, it.startTime, it.canDelete)
}
