package su.exbot.forms.routes.form.domain

import emotion.react.css
import react.FC
import react.StateInstance
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.textarea
import su.exbot.forms.client
import su.exbot.forms.elements.Icons
import su.exbot.forms.functions.TextFormat
import su.exbot.forms.routes.form.model.Form
import su.exbot.forms.routes.form.model.Item
import su.exbot.forms.routes.form.props.RawItemProps
import web.cssom.*
import kotlin.js.json

val TableRow = FC<RawItemProps> { props ->
    val data = props.data

    div {
        className = ClassName("question")

        p {
            css(ClassName("text-start")) {
                marginBottom = 6.px
            }
            span {
                css {
                    fontWeight = FontWeight.bold
                }
                +"Вопрос №${data.index}: "
            }
            +data.name
        }
        if(!data.text.isNullOrBlank()) {
            div {
                css {
                    fontSize = 15.px
                    fontStyle = FontStyle.italic
                    marginBottom = 6.px
                }
                TextFormat {
                    this.text = data.text
                }
            }
        }
        if(!data.answerToQuestion.isNullOrBlank()) {
            div {
                className = ClassName("mb-1")
                Icons.FileTextFill()
                span {
                    css(ClassName("align-middle")) {
                        fontSize = 14.px
                    }
                    +" Подсказка: "
                    span {
                        className = ClassName("fw-bold")
                        +data.answerToQuestion
                    }
                }
            }
        }
        val (color1, color2) = when(data.status) {
            1 -> listOf("var(--bs-success-border-subtle)", "var(--bs-success-bg-subtle)")
            2 -> listOf("var(--bs-primary-border-subtle)", "var(--bs-primary-bg-subtle)")
            3 -> listOf("var(--bs-danger-border-subtle)", "var(--bs-danger-bg-subtle)")
            else -> listOf(null, null)
        }
        if(data.rows == 1) {
            input {
                css(ClassName("form-control")) {
                    if(color1 != null) {
                        borderColor = Color(color1)
                    }
                    if(color2 != null) {
                        backgroundColor = Color(color2)
                    }
                }
                readOnly = true
                value = data.answer
                onClick = {
                    showStatusButtons(data, props.table.tableProps.contentItems)
                }
            }
        } else {
            val lines = data.answer.split("\n").sumOf { it.length / 60 }
            textarea {
                css(ClassName("form-control")) {
                    if(color1 != null) {
                        borderColor = Color(color1)
                    }
                    if(color2 != null) {
                        backgroundColor = Color(color2)
                    }
                }
                readOnly = true
                value = data.answer
                rows = if (lines < data.rows) data.rows else lines
                onClick = {
                    showStatusButtons(data, props.table.tableProps.contentItems)
                }
            }
        }
        if(data.editing == true) {
            div {
                className = ClassName("btn-group smooth-show")
                button {
                    className = ClassName("btn btn-success btn-sm")
                    +"Одобрить"
                    onClick = {
                        val tableProps = props.table.tableProps
                        val form = tableProps.form
                        if(form != null) {
                            changeStatus(data, form, 1, tableProps.contentItems)
                        }
                    }
                }
                button {
                    className = ClassName("btn btn-primary btn-sm")
                    +"50 на 50"
                    onClick = {
                        val tableProps = props.table.tableProps
                        val form = tableProps.form
                        if(form != null) {
                            changeStatus(data, form, 2, tableProps.contentItems)
                        }
                    }
                }
                button {
                    className = ClassName("btn btn-danger btn-sm")
                    +"Отказать"
                    onClick = {
                        val tableProps = props.table.tableProps
                        val form = tableProps.form
                        if(form != null) {
                            changeStatus(data, form, 3, tableProps.contentItems)
                        }
                    }
                }
                button {
                    className = ClassName("btn btn-secondary btn-sm")
                    +"Сбросить"
                    onClick = {
                        val tableProps = props.table.tableProps
                        val form = tableProps.form
                        if(form != null) {
                            changeStatus(data, form, 0, tableProps.contentItems)
                        }
                    }
                }
            }
        }
        div {
            css {
                marginTop = 3.px
            }
            Icons.HourglassSplit()
            span {
                css(ClassName("align-middle")) {
                    fontSize = 14.px
                }
                +" Старт "
                span {
                    css {
                        fontWeight = FontWeight.bold
                    }
                    +data.time.fromStart
                }
                +" // Вопрос "
                span {
                    css {
                        fontWeight = FontWeight.bold
                    }
                    +data.time.prevQuestion
                }
                +" // Ответ "
                span {
                    css {
                        fontWeight = FontWeight.bold
                    }
                    +data.time.prevResponse
                }
            }
        }
    }
}

private fun changeStatus(data: Item, form: Form, status: Int, useItems: StateInstance<MutableList<Item>?>) {
    client.patch<dynamic>(
        url = "/backend/user-forms/${form.userFormId}/answers/${data.id}",
        data = json( "status" to status)
    ).then {
        if(it.status == 200) {
            val (items, setItems) = useItems

            val contents = mutableListOf<Item>()
            for(item in items!!) {
                if(item.index == data.index) {
                    item.editing = false
                    item.status = it.data["status"] as Int
                    contents.add(Item(item))
                } else contents.add(item)
            }
            setItems(contents)
        }
    }
}

private fun showStatusButtons(data: Item, contentItems: StateInstance<MutableList<Item>?>) {
    val (items, setItems) = contentItems

    val contents = mutableListOf<Item>()
    for(item in items!!) {
        if(item.index == data.index) {
            item.editing = item.editing != true
            contents.add(Item(item))
        } else contents.add(item)
    }
    setItems(contents)
}