package su.exbot.forms.containers.panel.tabs.administrators.components

import emotion.react.css
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import react.FC
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import react.useEffect
import react.useState
import su.exbot.forms.client
import su.exbot.forms.profiles.ProfileData
import su.exbot.forms.containers.panel.tabs.administrators.interfaces.IAdminComponent
import su.exbot.forms.containers.panel.tabs.administrators.data.AdminEntity
import web.cssom.*
import web.window.WindowTarget

val AdminComponent = FC<IAdminComponent> { props ->
    val item = props.adminEntity
    val (items, setItems) = props.useItems

    tr {
        css {
            fontSize = 14.px
        }
        td {
            className = ClassName("text-center")
            +item.timeAtHuman
        }
        td {
            val profile = ProfileData.getById(item.userId)
            if(profile != null) {
                img {
                    className = ClassName("rounded-circle me-2")
                    width = 25.0
                    height = 25.0
                    src = profile.photo_50
                }
                a {
                    css {
                        color = Color("var(--bs-body-color)")
                        textDecoration = None.none
                    }
                    target = WindowTarget._blank
                    href = "https://vk.com/id${profile.id}"

                    +profile.firstAndLastName
                }
            } else {
                ProfileData.addQueue(item.userId.toLong())

                div {
                    css(ClassName("placeholder me-2")) {
                        width = 25.px
                        height = 25.px
                        borderRadius = 50.pct
                    }
                }
                div {
                    css(ClassName("placeholder")) {
                        width = (100 + ((1..3).random() * 13)).px
                    }
                }
            }
        }
        td {
            val (selectedLevel, setSelectedLevel) = useState(item.level)
            useEffect {
                if(selectedLevel != item.level) {
                    setSelectedLevel(item.level)
                }
            }
            select {
                css(ClassName("form-control")) {
                    padding = Padding(.1.rem, .25.rem, .1.rem, 1.rem)
                    fontSize = 14.px
                }
                disabled = !item.canModify
                value = selectedLevel
                (5 downTo 1).forEach {
                    option {
                        disabled = it == selectedLevel
                        value = it
                        +" – $it ур."
                    }
                }
                onChange = { e ->
                    client.patch<dynamic>(
                        url = "/backend/admins/${props.formId}/${item.id}",
                        data = buildJsonObject {
                            put("level", e.target.value.toInt())
                        }.toString()
                    ).then {
                        if(it.status == 200) {
                            val admin = it.data["item"].unsafeCast<AdminEntity>()

                            val list = items.filter { item ->
                                item.id != admin.id
                            }.toTypedArray()

                            setItems(list + admin)
                        }
                    }
                }
            }
        }
        td {
            val profile = ProfileData.getById(item.fromId)
            if(profile != null) {
                img {
                    className = ClassName("rounded-circle me-2")
                    width = 25.0
                    height = 25.0
                    src = profile.photo_50
                }
                a {
                    css {
                        color = Color("var(--bs-body-color)")
                        textDecoration = None.none
                    }
                    target = WindowTarget._blank
                    href = "https://vk.com/id${profile.id}"

                    +profile.firstAndLastName
                }
            } else {
                ProfileData.addQueue(item.fromId.toLong())

                div {
                    css(ClassName("placeholder me-2")) {
                        width = 25.px
                        height = 25.px
                        borderRadius = 50.pct
                    }
                }
                div {
                    css(ClassName("placeholder")) {
                        width = (100 + ((1..3).random() * 13)).px
                    }
                }
            }
        }
        td {
            button {
                disabled = !item.canModify
                css(ClassName("btn btn-danger bi bi-trash3")) {
                    asDynamic()["--bs-btn-padding-y"] = 0.1.rem
                    asDynamic()["--bs-btn-padding-x"] = 0.4.rem
                    asDynamic()["--bs-btn-font-size"] = 0.75.rem
                }
                onClick = {
                    client.delete<dynamic>("/backend/admins/${props.formId}/${item.id}").then {
                        if(it.status == 200) {
                            val adminId = it.data["adminId"] as Int

                            setItems(items.filter {
                                    item -> item.id != adminId
                            }.toTypedArray())
                        }
                    }
                }
            }
        }
    }
}