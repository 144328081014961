package su.exbot.forms.routes.list.model

@OptIn(ExperimentalJsExport::class)
@JsExport
data class Item(
    val id: Int,
    val userId: Int,
    val date: String,
    val token: String,
    val formName: String,
    val formId: Int,
    var status: Int,
    val utm: String?,
    val answered: Int,
    val questions: Int,
    val commentsToForm: Int?,
    val commentsToUser: Int?,
)
