package su.exbot.forms.routes.form.domain

import web.cssom.ClassName
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h5
import su.exbot.forms.routes.form.props.GroupProps

val CreateGroup = FC<GroupProps> { props ->
    div {
        className = ClassName("group")
        h5 {
            +props.group
        }
    }
    props.items.forEach {
        TableRow {
            this.data = it
            this.table = props
        }
    }
}