package su.exbot.forms

import kotlinx.browser.window
import web.dom.document

fun setTheme(name:String? = null): String {
    val e = document.documentElement
    return if(name == null) {
        var value = e.getAttribute("data-bs-theme")
        if(value == null) {
            value = if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
                "light"
            } else "dark"
        }
        value = if(value == "light") "dark" else "light"
        e.setAttribute("data-bs-theme", value)
        value
    } else {
        e.setAttribute("data-bs-theme", name)
        name
    }
}