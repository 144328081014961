package ru.codeoff.bots.sdk.calls

import js.objects.jso
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromDynamic
import ru.codeoff.bots.sdk.interfaces.ExecuteCallback
import ru.codeoff.bots.sdk.model.VkApiResponse
import su.exbot.forms.client
import kotlin.js.json

class CallAsync(methodName: String, values: Map<String, Any>, callback: ExecuteCallback) : Call() {
    private val callback: ExecuteCallback

    init {
        this.methodName = methodName
        this.params = values
        this.callback = callback
    }

    @OptIn(ExperimentalSerializationApi::class)
    fun submit() {
        client.post<dynamic>(
            url = "/method/$methodName",
            data = params.entries.joinToString("&") { "${it.key}=${it.value}" },
            config = jso {
                headers = json(
                    "Content-Type" to "application/x-www-form-urlencoded"
                )
            }
        ).then {
            val data = Json.decodeFromDynamic<VkApiResponse>(it.data)
            if (data.error != null) {
                callback.onAction(null, data.error)
                println(data.error)
            } else if (data.response != null) {
                callback.onAction(data.response, null)
            }
        }
    }
}