package su.exbot.forms.containers.panel.tabs.questions

import emotion.react.css
import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.router.useParams
import react.useEffectOnce
import react.useState
import su.exbot.forms.client
import su.exbot.forms.containers.panel.tabs.questions.components.QuestionComponent
import su.exbot.forms.containers.panel.tabs.questions.components.QuestionEditedComponent
import su.exbot.forms.containers.panel.tabs.questions.model.QuestionEntity
import web.cssom.ClassName
import web.cssom.None
import web.cssom.rem
import web.cssom.vh

val QuestionsContainer = FC {
    val useQuestions = useState<Array<QuestionEntity>>()
    val (questions, setQuestions) = useQuestions

    val formId = useParams()["formId"]

    useEffectOnce {
        client.get<Array<QuestionEntity>>("/backend/forms/$formId/questions").then {
            if(it.status == 200) {
                setQuestions(it.data)
            }
        }
    }

    div {
        css(ClassName("row accordion")) {
            maxWidth = 45.rem
            asDynamic()["--bs-accordion-btn-focus-border-color"] = None.none
            asDynamic()["--bs-accordion-btn-focus-box-shadow"] = None.none
            asDynamic()["--bs-accordion-active-color"] = None.none
            asDynamic()["--bs-accordion-active-bg"] = None.none
        }
        questions?.forEach {
            if(it.editMode) {
                QuestionEditedComponent {
                    this.question = it
                    this.useQuestions = useQuestions
                }
            } else {
                QuestionComponent {
                    this.question = it
                    this.useQuestions = useQuestions
                }
            }
        }
    }
    if(questions?.none { it.id == 0 } == true) {
        div {
            className = ClassName("row")
            button {
                className = ClassName("btn btn-dark btn-sm bi bi-plus-square-dotted")
                +" Создание нового вопроса"
                onClick = {
                    val questionEntities = questions + QuestionEntity(
                        id = 0,
                        formId = formId!!.toInt(),
                        name = "",
                        text = "",
                        answer = "",
                        rows = 1,
                        editMode = true,
                        canInteract = true
                    )
                    setQuestions(questionEntities)
                }
            }
        }
    }
}