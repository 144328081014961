package su.exbot.forms.containers.panel.tabs.administrators.data

@OptIn(ExperimentalJsExport::class)
@JsExport
data class AdminEntity(
    val id: Int,
    val userId: Int,
    var level: Int,
    val fromId: Int,
    val timeAtHuman: String,
    val canModify: Boolean
)
