package su.exbot.forms

import generated.Snackbar
import generated.axios
import js.objects.jso
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.get
import org.w3c.dom.set
import react.Fragment
import react.create
import react.dom.client.createRoot
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.p
import react.router.RouterProvider
import su.exbot.forms.functions.authorize
import su.exbot.forms.model.AdminData
import web.cssom.ClassName
import web.dom.document
import kotlin.js.Json
import kotlin.js.json

const val projectName = "EscoForms"

var host = "//${getServerAPI()}"
var adminRights = listOf<AdminData>()

var client = axios.create(jso {
    baseURL = host
    timeout = 2 * 60 * 1000
    headers = json(
        "Content-Type" to "application/json",
        "version" to versionApplication
    )
    validateStatus = {
        true
    }
})

fun main() {
    val container = document.getElementById("root")
    if (container != null) {
        val themeName = localStorage["theme"]
        if(themeName != null) setTheme(themeName)
        else localStorage["theme"] = setTheme()

        val root = createRoot(container)
        document.title = "Загрузка..."

        root.render(Fragment.create {
            div {
                className = ClassName("container")
                div {
                    className = ClassName("loading")
                    h3 {
                        className = ClassName("loading-element")
                        +"Загрузка данных"
                    }
                    p {
                        +"Идет загрузка данных с учетной записи"
                    }
                }
            }
        })
        authorize { type, reason ->
            if (type == "authorization") {
                document.title = "$projectName (RADMIR)"
                root.render(Fragment.create {
                    RouterProvider { router = appRouter }
                })
                client.interceptors.request.use({ request ->
                    request.headers.unsafeCast<Json>().add(json(
                        "Authorization" to localStorage["panel_token"]
                    ))
                    request
                })
            } else if(type == "not_authorized") {
                document.title = "Ошибка!"
                root.render(Fragment.create {
                    div {
                        className = ClassName("container")
                        div {
                            className = ClassName("loading")
                            h3 {
                                +"Ошибка авторизации"
                            }
                            p {
                                +(reason?:"Не удалось авторизировать пользователя")
                            }
                            button {
                                className = ClassName("btn btn-dark")
                                +"Повторить попытку"
                                onClick = {
                                    window.location.href = window.location.origin
                                }
                            }
                        }
                    }
                })
            }
        }
        client.interceptors.response.use({ response ->
            if(response.status !in 200..299) {
                Snackbar("error", response.data["message"].toString())
            }
            response
        })
    }
}

fun getServerAPI(): String {
    return localStorage["host"] ?: window.location.host
}