package su.exbot.forms.containers.panel.tabs.userforms.components

import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import su.exbot.forms.routes.list.domain.UserFormsTableRow
import su.exbot.forms.routes.list.props.ContainerProps
import web.cssom.ClassName

val UserFormsTable = FC<ContainerProps> { props ->
    val items = props.items
    val isLoading = props.loading
    if(items.isNullOrEmpty()) return@FC

    div {
        className = ClassName("row table-responsive px-4")
        table {
            className = ClassName("table table-hover")
            thead {
                tr {
                    className = ClassName("text-center")
                    th {

                    }
                    th {
                        +"Дата"
                    }
                    th {
                        +"Пользователь"
                    }
                    th {
                        +"Форма"
                    }
                    th {
                        +"Ключ / Прогресс"
                    }
                }
            }
            tbody {
                className = ClassName("text-nowrap")
                items.filter { item -> props.statutes.any { item.status == it } }.forEach {
                    UserFormsTableRow {
                        this.item = it
                    }
                }
            }
        }
    }

    if(props.nextOffset >= 0) {
        div {
            className = ClassName("row")
            button {
                className = ClassName("btn btn-dark")
                disabled = isLoading
                +(if (isLoading) "Загрузка данных..." else "Загрузить еще")
                onClick = {
                    props.functionUpdate()
                }
            }
        }
    }
}