package su.exbot.forms.model.enums

enum class Permissions {
    CAN_CREATE_FORM, // Доступ к созданию форм
    CAN_DELETE_FORM, // Доступ к удалению форм
    ACCESS_MODIFY_FORM, // Доступ к изменению форм
    ACCESS_TO_ADMINISTRATORS, // Доступ к просмотру админов
    ACCESS_TO_REQUESTS, // Доступ к формам пользователей
    CAN_MODIFY_ADMIN, // Может изменять/удалять права админов
    ACCESS_TO_QUESTIONS, // Доступ к вопросам
    CAN_CHANGE_STATUS_FORM, // Может изменять статус формы
    CAN_DELETE_USER_FORM, // Может удалить формы пользователя
    CAN_LOADING_USER_FORMS, // Может удалить формы пользователя
    CAN_QUESTION_CREATE, // Доступ к созданию вопросов
    CAN_QUESTION_DELETE, // Доступ к удалению вопросов (Созданных самим же)
    CAN_QUESTION_RESTORE, // Доступ к d вопросов (Созданных самим же)
    CAN_QUESTION_EDIT, // Доступ к редактированию вопросов
    CAN_VIEW_COMMENTS, // Доступ к просмотру комментариев
    CAN_CREATE_COMMENT, // Доступ к созданию комментария
    CAN_EDIT_COMMENT, // Доступ к редактированию комментариев
    CAN_DELETE_COMMENT, // Доступ к удалению комментариев
}