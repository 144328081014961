package su.exbot.forms.profiles.model

import kotlinx.serialization.Serializable
import su.exbot.forms.profiles.interfaces.ProfileInterface

@OptIn(ExperimentalJsExport::class)
@JsExport
@Serializable
data class GroupData(
    override val id: Int,
    val name: String,
    override val photo_50: String
): ProfileInterface {

    override val firstAndLastName = this.name
}
