package su.exbot.forms.profiles.model

import kotlinx.serialization.Serializable
import su.exbot.forms.profiles.interfaces.ProfileInterface

@OptIn(ExperimentalJsExport::class)
@JsExport
@Serializable
data class UserData(
    override val id: Int,
    val first_name: String,
    val last_name: String,
    override val photo_50: String
): ProfileInterface {

    override val firstAndLastName = "$first_name $last_name"
}
