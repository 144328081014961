package su.exbot.forms.containers.panel.tabs.questions.model

@OptIn(ExperimentalJsExport::class)
@JsExport
data class QuestionEntity(
    var id: Int,
    val formId: Int,
    var name: String,
    var text: String?,
    var answer: String?,
    val rows: Int,
    var editMode: Boolean,
    var canInteract: Boolean = false
)
