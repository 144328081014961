package su.exbot.forms.containers.panel.index.model

@OptIn(ExperimentalJsExport::class)
@JsExport
data class FormEntity(
    var id: Int,
    var name: String,
    val level: Int,
    val forms: Int
)
