package su.exbot.forms.functions

import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.span
import web.window.WindowTarget

external interface TextFormatProps: Props {
    var text: String
}

enum class FormatType(val regex: Regex?) {
    TEXT(null),
    LINE(Regex("\n")),
    LINK(Regex("(https?)://[-a-zA-ZА-Яа-я0-9+&@#/%?=~_|!:,.;]*[-a-zA-ZА-Яа-я0-9+&@#/%=~_|]")),
    MENTION(Regex("\\[((club|id)\\d+)\\|(.+)\\]")),
    MENTION2(Regex("@((club|id)\\d+) \\(([A-Za-z0-9А-Яа-я]*)\\)")),
    VKLINK(Regex("(?:https?://)?(?:www\\.)?vk\\.(com|ru|me)/(?:[\\w.]+/?)+\\??[a-zA-Z0-9_=&%-]*")),
}

val TextFormat = FC<TextFormatProps> {
    val array = mutableListOf(it.text to FormatType.TEXT)

    FormatType.entries.filter { e -> e != FormatType.TEXT }.forEach { e ->
        array.forEachIndexed { index, (text, type) ->
            if(type == FormatType.TEXT) {
                val list = e.regex!!.findAll(text).toList()
                if (list.isNotEmpty()) {
                    array.removeAt(index)
                    text.split(e.regex).forEachIndexed { indexSplit, textSplit ->
                        val newIndex = index + (indexSplit * 2)

                        array.add(newIndex, textSplit to FormatType.TEXT)
                        if (list.size - 1 >= indexSplit) {
                            array.add(newIndex + 1, list[indexSplit].value to e)
                        }
                    }
                }
            }
        }
    }

    array.forEach { (text, type) ->
        when(type) {
            FormatType.TEXT -> span {
                +text
            }
            FormatType.LINE -> br()
            FormatType.LINK, FormatType.VKLINK -> a {
                href = if(text.startsWith("http")) text else "http://$text"
                target = WindowTarget._blank
                +text
            }
            FormatType.MENTION, FormatType.MENTION2 -> {
                val match = type.regex!!.find(text)
                if(match != null) {
                    a {
                        href = "https://vk.com/${match.groups[1]!!.value}"
                        target = WindowTarget._blank
                        +match.groups[3]!!.value
                    }
                }
            }
        }
    }
}