package su.exbot.forms

import org.w3c.dom.Element
import react.ChildrenBuilder
import react.dom.html.HTMLAttributes
import react.router.dom.Link
import su.exbot.forms.model.AdminData
import web.cssom.ClassName
import web.cssom.Color
import web.html.HTMLElement

inline fun Element.select(): dynamic = asDynamic().select()
inline fun Element.focus(): dynamic = asDynamic().focus()

fun ChildrenBuilder.to(name:String, link:String) {
    Link {
        className = ClassName("list-group-item")
        to = link
        +name
    }
}

var HTMLAttributes<*>.dataBsToggle: String?
    get() = asDynamic()["data-bs-toggle"] as? String
    set(value) {
        asDynamic()["data-bs-toggle"] = value
    }

var HTMLAttributes<*>.dataBsTarget: String?
    get() = asDynamic()["data-bs-target"] as? String
    set(value) {
        asDynamic()["data-bs-target"] = value
    }

external interface Focusable {
    fun focus()
}

class FocusableElement(
    private val element: HTMLElement,
) : Focusable {
    override fun focus() {
        element.focus()
    }
}

data class FormStatus(val color: Color, val name: String, val action: String)

fun getFormStatus(status: Int): FormStatus {
    return when(status) {
        1 -> FormStatus(Color("#e2be00"), "В рассмотрение", "В рассмотрение")
        2 -> FormStatus(Color("#ff4e4e"), "Отклонено", "Отклонить заявку")
        3 -> FormStatus(Color("#49a66d"), "Одобрено", "Одобрить заявку")
        else -> FormStatus(Color("#c4c4c4"), "Не пройдено", "Не пройдено")
    }
}

fun generateColor(text: String): String {
    val hash = text.reversed().hashCode()
    val hue = hash % 360
    val saturation = 70 + (hash % 30)
    val lightness = 20 + (hash % 35)
    return "hsl($hue, $saturation%, $lightness%)"
}

fun List<AdminData>.getByFormId(formId: Int): AdminData? {
    return adminRights.firstOrNull { it.formId == -1 || it.formId == formId }
}

fun chooseWordForm(ed: String, a: String, b: String, c: String, d: Int): String {
    val absD = if (d < 0) -d else d

    var last = (absD % 100)
    if (last in 11..20) return ed + c

    last = (absD % 10)

    return when {
        last == 0 || last > 4 -> ed + c
        last == 1 -> ed + a
        last < 5 -> ed + b
        else -> ed + c
    }
}