package su.exbot.forms.containers.placeholder

import emotion.react.css
import js.objects.jso
import kotlinx.browser.window
import org.w3c.dom.INSTANT
import org.w3c.dom.ScrollBehavior
import react.FC
import react.dom.html.ReactHTML.div
import su.exbot.forms.containers.placeholder.props.IPlaceholderComponent
import web.cssom.*
import kotlin.random.Random

private val commonClass = ClassName("w-100 placeholder rounded")

val PlaceholderContainer = FC<IPlaceholderComponent> { props ->
    val createGroup = FC {
        if(props.title > 0) {
            div {
                css(commonClass) {
                    this.display = Display.grid
                    this.height = props.title.px
                    this.marginTop = 25.px
                    this.marginBottom = 10.px
                }
            }
        }
    }
    window.scrollTo(jso {
        this.top = 0.0
        this.behavior = ScrollBehavior.INSTANT
    })
    div {
        css(ClassName("placeholder-glow")) {
            this.maxWidth = 500.px
            this.margin = Margin(0.px, Auto.auto)
        }
        if(props.board != null) {
            createGroup()
            div {
                css(commonClass) {
                    this.display = Display.grid
                    this.height = props.board!!.px
                }
            }
            div {
                css(commonClass) {
                    this.display = Display.grid
                    this.marginTop = 10.px
                    this.height = 110.px
                }
            }
        }
        repeat(3) {
            createGroup()
            repeat(props.points) {
                div {
                    css(commonClass) {
                        this.display = Display.grid
                        this.margin = Margin(5.px, Auto.auto)
                        this.height = Random.nextInt(props.formMin, props.formMax).px
                    }
                }
            }
        }
    }
}