package su.exbot.forms.containers.panel.index

import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.router.Outlet
import react.router.useNavigate
import react.router.useParams
import react.useEffectOnce
import react.useState
import su.exbot.forms.adminRights
import su.exbot.forms.client
import su.exbot.forms.getByFormId
import su.exbot.forms.model.enums.Permissions
import su.exbot.forms.containers.panel.index.interfaces.IFormComponent
import su.exbot.forms.containers.panel.index.model.FormEntity
import su.exbot.forms.containers.panel.tabs.userforms.UserFormsContainer
import web.cssom.ClassName
import web.html.ButtonType
import kotlin.js.json

var PanelContainer = FC<IFormComponent> { props ->
    val (forms, setForms) = props.useForms

    val (selectedForm, setSelectedForm) = useState(0)
    val (groupName, setGroupName) = useState("")

    val adm = adminRights.getByFormId(selectedForm)
    val navigate = useNavigate()
    val formId = useParams()["formId"]

    useEffectOnce {
        if(formId == null && forms.isNotEmpty()) {
            forms.first().apply {
                navigate("/panel/${this.id}")
                setSelectedForm(this.id)
            }
        } else if(formId != null && selectedForm == 0) {
            setSelectedForm(formId.toInt())
        }
    }

    div {
        className = ClassName("container form-container2")
        div {
            className = ClassName("row justify-content-center")

            div {
                className = ClassName("col-md-auto")
                select {
                    className = ClassName("form-select form-select-sm mb-lg-0 mb-2")
                    value = selectedForm

                    adminRights.getByFormId(-1).let {
                        if(it != null && it.has(Permissions.CAN_CREATE_FORM)) {
                            option {
                                value = 0
                                +"Создать форму"
                            }
                        }
                    }
                    forms.forEach {
                        option {
                            value = it.id
                            +" – ${it.name} [ID: ${it.id}]"
                        }
                    }
                    onChange = {
                        val selectFormId = it.target.value.toInt()
                        if(selectFormId > 0) {
                            UserFormsContainer.reset()
                            navigate("/panel/$selectFormId")
                        } else {
                            navigate("/panel")
                        }
                        setSelectedForm(selectFormId)
                    }
                }
            }
            div {
                className = ClassName("col-md-auto")
                if (selectedForm == 0) {
                    div {
                        className = ClassName("input-group")
                        input {
                            className = ClassName("form-control form-control-sm")
                            placeholder = "Введите название"
                            value = groupName
                            onChange = {
                                setGroupName(it.target.value)
                            }
                        }
                        button {
                            className = ClassName("btn btn-success btn-sm")
                            type = ButtonType.button
                            +"Создать форму"
                            onClick = {
                                client.post<dynamic>(
                                    url = "/backend/forms",
                                    data = json(
                                        "name" to groupName
                                    )
                                ).then {
                                    if(it.status == 200) {
                                        val formData = it.data["item"].unsafeCast<FormEntity>()
                                        setForms(forms + formData)
                                    }
                                }
                            }
                        }
                    }
                } else {
                    button {
                        className = ClassName("btn btn-danger btn-sm")
                        type = ButtonType.button
                        disabled = adm == null || !adm.has(Permissions.CAN_DELETE_FORM)
                        +"Удалить форму"
                        onClick = {
                            client.delete<dynamic>("/backend/forms/${selectedForm}").then {
                                if(it.status == 200) {
                                    val deletedFormId = it.data["formId"] as Int

                                    forms.filter { item ->
                                        item.id != deletedFormId
                                    }.toTypedArray().apply {
                                        setForms(this)
                                        setSelectedForm(0)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        Outlet()
    }
}