package su.exbot.forms.routes.main.domain.input

import js.objects.jso
import react.ForwardRef
import react.dom.html.ReactHTML.textarea
import react.useImperativeHandle
import react.useRef
import react.useState
import su.exbot.forms.FocusableElement
import su.exbot.forms.routes.main.props.InputRefProps
import web.cssom.ClassName
import web.cssom.px
import web.html.HTMLTextAreaElement

val FormTextArea = ForwardRef<_, InputRefProps> { props ->
    val item = props.item
    val inputRef = useRef<HTMLTextAreaElement>()

    useImperativeHandle(props.ref, inputRef) {
        inputRef.current?.let(::FocusableElement)
    }

    textarea {
        ref = inputRef
        className = ClassName("form-control")
        disabled = item.edited != true || item.edited == null
        placeholder = "Введите текст и нажмите кнопку снизу"
        rows = item.rows
        maxLength = item.limit
        defaultValue = item.answer
        var height by useState(0)
        if (height > 70) {
            style = jso {
                this.height = height.px
            }
        }
        onInput = {
            height = it.currentTarget.scrollHeight
            item.answer = it.currentTarget.value
        }
    }
}