package su.exbot.forms.routes.list.domain

import emotion.react.css
import js.objects.jso
import kotlinx.browser.window
import su.exbot.forms.profiles.ProfileData
import react.FC
import react.dom.aria.AriaRole
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import react.router.useNavigate
import su.exbot.forms.generateColor
import su.exbot.forms.getFormStatus
import su.exbot.forms.containers.panel.tabs.userforms.interfaces.IUserFormComponent
import web.cssom.*
import web.html.HTMLTableCellElement
import web.window.WindowTarget

var UserFormsTableRow = FC<IUserFormComponent> { props ->
    val item = props.item
    val navigate = useNavigate()

    tr {
        css {
            fontSize = 14.px
            cursor = Cursor.pointer
        }
        td {
            className = ClassName("align-middle")
            div {
                val color = getFormStatus(item.status).color
                css {
                    borderRadius = 50.pct
                    width = 8.px
                    height = 8.px
                    backgroundColor = color
                    boxShadow = BoxShadow(0.px, 0.px, 8.px, 2.px, color)
                }
            }
        }
        td {
            +item.date
        }
        td {
            val profile = ProfileData.getById(item.userId)
            if(profile != null) {
                img {
                    className = ClassName("rounded-circle me-2")
                    width = 25.0
                    height = 25.0
                    src = profile.photo_50
                }
                a {
                    css {
                        color = Color("var(--bs-body-color)")
                        textDecoration = None.none
                    }
                    target = WindowTarget._blank
                    href = "https://vk.com/id${profile.id}"

                    +profile.firstAndLastName
                }
            } else {
                ProfileData.addQueue(item.userId.toLong())

                div {
                    css(ClassName("placeholder me-2")) {
                        width = 25.px
                        height = 25.px
                        borderRadius = 50.pct
                    }
                }
                div {
                    css(ClassName("placeholder")) {
                        width = (100 + ((1..3).random() * 13)).px
                    }
                }
            }
            if(item.commentsToUser != null) {
                span {
                    className = ClassName("badge bg-secondary ms-2")
                    +"+${item.commentsToUser}"
                }
            }
        }
        td {
            className = ClassName("text-center")
            +"${item.formName} [ID: ${item.formId}]"
            if(!item.utm.isNullOrBlank()) {
                span {
                    css(ClassName("badge ms-1")) {
                        backgroundColor = Color(generateColor(item.utm))
                    }
                    +item.utm
                }
            }
            if(item.commentsToForm != null) {
                span {
                    className = ClassName("badge bg-secondary ms-1")
                    +"+${item.commentsToForm}"
                }
            }
        }
        td {
            if(item.status == 0) {
                val progress = (item.answered*100/item.questions)
                div {
                    className = ClassName("progress")
                    role = AriaRole.progressbar
                    if(progress > 0) {
                        div {
                            val color = when(progress) {
                                in 15..45 -> "bg-warning"
                                in 46..79 -> "bg-primary"
                                in 80..100 -> "bg-success"
                                else -> "bg-danger"
                            }
                            className = ClassName("progress-bar $color")
                            style = jso {
                                width = progress.pct
                            }
                            +"${progress}%"
                        }
                    }
                }
            } else {
                className = ClassName("text-center")
                span {
                    //+(getFormStatus(item.status).name)
                    +item.token
                }
            }
        }
        onClick = {
            if(it.target is HTMLTableCellElement) {
                if(it.ctrlKey) {
                    window.open("/request?id=${item.id}", "_blank")
                } else {
                    navigate("/request/${item.id}")
                }
            }
        }
    }
}