package generated

import js.objects.jso
import web.cssom.Length

@JsName("SnackBar")
external fun snackBar(options: SnackOptions)

@Suppress("unused")
external interface SnackOptions {
    var message: String
    var container: String
    var position: String
    var status: String
    var icon: String
    var speed: dynamic
    var timeout: dynamic
    var width: Length
    var dismissible: Boolean
    var fixed: Boolean
}

fun Snackbar(status:String, message: String) {
    snackBar(jso{
        this.status = status
        this.message = message
        this.fixed = true
    })
}