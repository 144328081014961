package su.exbot.forms.containers.panel.index

import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul
import react.router.Outlet
import react.router.dom.Link
import react.router.useParams
import react.useState
import su.exbot.forms.adminRights
import su.exbot.forms.enums.TabType
import su.exbot.forms.getByFormId
import web.cssom.ClassName

val PanelTabsContainer = FC {
    val formId = useParams()["formId"]
    val adm = adminRights.getByFormId(formId!!.toInt())

    val (selected, setSelected) = useState<TabType?>(null)

    div {
        className = ClassName("row tabs is-centered")
        ul {
            TabType.entries.forEach { en ->
                if (adm != null && adm.has(en.permission)) {
                    li {
                        if(en == selected) {
                            className = ClassName("is-active")
                        }
                        Link {
                            to = en.path
                            +en.title
                            onClick = {
                                setSelected(en)
                            }
                        }
                    }
                }
            }
        }
    }
    Outlet()
}