package su.exbot.forms.routes.comments.domain

import emotion.react.css
import su.exbot.forms.profiles.ProfileData
import react.FC
import react.dom.aria.ariaExpanded
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import su.exbot.forms.client
import su.exbot.forms.dataBsToggle
import su.exbot.forms.functions.TextFormat
import su.exbot.forms.routes.comments.model.CommentEntity
import su.exbot.forms.routes.comments.props.CommentProps
import web.cssom.*
import web.window.WindowTarget

val DisplayComment = FC<CommentProps> { props ->
    val item = props.item
    val userFormId = props.board.userFormId

    div {
        css(ClassName("position-relative")) {
            padding = 3.px
        }
        div {
            css {
                fontSize = 14.px
                position = Position.absolute
                right = 0.px
            }
            span {
                css {
                    fontSize = 14.px
                }
                +item.dateAtHuman
            }
            if(item.canEdit || item.canDelete) {
                span {
                    dataBsToggle = "dropdown"
                    ariaExpanded = false
                    css(ClassName("bi bi-three-dots-vertical")) {
                        cursor = Cursor.pointer
                        marginInlineStart = 1.px
                    }
                }
                ul {
                    className = ClassName("dropdown-menu")
                    a {
                        className = ClassName("bi bi-pencil-fill dropdown-item")
                        +" Редактировать"
                        onClick = {
                            val (comments, setComments) = props.board.useComments

                            var rows = arrayOf<CommentEntity>()
                            for(row in comments) {
                                if(row.id == item.id) {
                                    row.edited = !row.edited
                                }
                                rows += row
                            }
                            setComments(rows)
                        }
                    }
                    a {
                        className = ClassName("dropdown-item")
                        i {
                            className = ClassName("bi bi-trash text-danger")
                        }
                        +" Удалить комм."
                        onClick = {
                            client.delete<dynamic>(
                                url = "/backend/user-forms/$userFormId/comments/${item.id}"
                            ).then {
                                if(it.status == 200) {
                                    val (comments, setComments) = props.board.useComments

                                    val rows = comments.filter { row ->
                                        row.id != item.id
                                    }
                                    setComments(rows.toTypedArray())
                                }
                            }
                        }
                    }
                }
            }
        }
        div {
            val profile = ProfileData.getById(item.fromId)
            if(profile != null) {
                img {
                    className = ClassName("rounded-circle me-2")
                    width = 25.0
                    height = 25.0
                    src = profile.photo_50
                }
                a {
                    css {
                        color = Color("var(--bs-body-color)")
                        textDecoration = None.none
                        fontSize = 14.px
                    }
                    target = WindowTarget._blank
                    href = "https://vk.com/id${profile.id}"
                    +profile.firstAndLastName
                }
            } else {
                div {
                    css(ClassName("placeholder me-2")) {
                        width = 25.px
                        height = 25.px
                        borderRadius = 50.pct
                    }
                }
                div {
                    css(ClassName("placeholder")) {
                        width = 120.px
                    }
                }
                ProfileData.addQueue(item.fromId.toLong())
            }
            if(item.type == 1) {
                i {
                    className = ClassName("bi bi-pin-angle mx-1")
                }
            } else if(item.type == 2) {
                i {
                    className = ClassName("bi bi-gear mx-1")
                }
            }
        }
        div {
            css {
                fontSize = 14.px
                marginBlock = 0.5.rem
            }
            TextFormat {
                this.text = item.text
            }
        }
    }
}