package su.exbot.forms.containers.panel.tabs.configuring.components

import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.span
import su.exbot.forms.containers.panel.tabs.configuring.interfaces.IFieldComponent
import web.cssom.ClassName

val FieldRow = FC<IFieldComponent> {
        div {
            className = ClassName("row")
            div {
                className = ClassName("col-md-auto")
                label {
                    +"Dsdasd"
                }
                input {
                    className = ClassName("form-control")

                }
            }
        }

}