package su.exbot.forms.routes.form.model

@OptIn(ExperimentalJsExport::class)
@JsExport
data class Item(
    val index:Int,
    val id:Int,
    val name:String,
    val text:String?,
    val group:String,
    var answer:String,
    val rows:Int,
    var editing: Boolean?,
    val time: ResponseTime,
    val answerToQuestion: String?,
    var status: Int?
)

fun Item(item: Item): Item {
    return Item(
        item.index,
        item.id,
        item.name,
        item.text,
        item.group,
        item.answer,
        item.rows,
        item.editing,
        item.time,
        item.answerToQuestion,
        item.status
    )
}