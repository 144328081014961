package su.exbot.forms.containers.panel

import kotlinx.browser.document
import react.FC
import react.useEffectOnce
import react.useState
import su.exbot.forms.client
import su.exbot.forms.containers.panel.index.PanelContainer
import su.exbot.forms.containers.panel.index.model.FormEntity
import su.exbot.forms.projectName

var arrayForms = arrayOf<FormEntity>()

val IndexPanelContainer = FC {
    document.title = "$projectName | Параметры форм"

    val useItems = useState(arrayForms)
    val (items, setItems) = useItems

    useEffectOnce {
        if(arrayForms.isEmpty()) {
            client.get<Array<FormEntity>>("/backend/forms").then {
                if(it.status == 200) {
                    arrayForms = it.data
                    setItems(arrayForms)
                }
            }
        }
    }

    if(items.isNotEmpty()) {
        PanelContainer {
            this.useForms = useItems
        }
    }
}