package su.exbot.forms

import js.objects.jso
import kotlinx.browser.window
import org.w3c.dom.url.URL
import react.FC
import react.create
import react.router.dom.createBrowserRouter
import react.router.useNavigate
import react.useEffect
import su.exbot.forms.containers.panel.IndexPanelContainer
import su.exbot.forms.containers.panel.index.PanelTabsContainer
import su.exbot.forms.containers.panel.tabs.administrators.AdminsContainer
import su.exbot.forms.containers.panel.tabs.configuring.ConfiguringContainer
import su.exbot.forms.containers.panel.tabs.questions.QuestionsContainer
import su.exbot.forms.containers.panel.tabs.userforms.UserFormsContainer
import su.exbot.forms.elements.navbar
import su.exbot.forms.routes.form.RequestRoute
import su.exbot.forms.routes.main.IndexRoute

val appRouter = createBrowserRouter(
    arrayOf(
        jso {
            path = "/"
            element = navbar.create()
            children = arrayOf(
                jso {
                    index = true
                    element = IndexRoute.element().create()
                },
                jso {
                    path = "/request"
                    children = arrayOf(
                        jso {
                            index = true
                            element = FC {
                                val url = URL(window.location.href)
                                val params = url.searchParams
                                val navigate = useNavigate()

                                if(params.get("id") != null) {
                                    useEffect {
                                        navigate("/request/${params.get("id")}")
                                    }
                                }
                            }.create()
                        },
                        jso {
                            path = ":formId"
                            element = RequestRoute.element().create()
                        },
                    )
                },
                jso {
                    path = "/panel"
                    element = IndexPanelContainer.create()
                    children = arrayOf(jso {
                        path = ":formId"
                        element = PanelTabsContainer.create()
                        children = arrayOf(
                            jso {
                                index = true
                                element = UserFormsContainer.index.create()
                            },
                            jso {
                                path = "user-forms"
                                element = UserFormsContainer.index.create()
                            },
                            jso {
                                path = "admin-list"
                                element = AdminsContainer.create()
                            },
                            jso {
                                path = "configuring-forms"
                                element = ConfiguringContainer.create()
                            },
                            jso {
                                path = "questions"
                                element = QuestionsContainer.create()
                            },
                        )
                    })
                },
            )
        },
    )
)