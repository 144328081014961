package su.exbot.forms.profiles

import generated.Snackbar
import kotlinx.serialization.json.*
import react.StateInstance
import ru.codeoff.bots.sdk.calls.CallAsync
import ru.codeoff.bots.sdk.interfaces.ExecuteCallback
import su.exbot.forms.profiles.interfaces.ProfileInterface
import su.exbot.forms.profiles.model.GroupData
import su.exbot.forms.profiles.model.UserData
import su.exbot.forms.versionApiVK

object ProfileData {

    var list: List<ProfileInterface> = listOf()
    val queue: MutableSet<Long> = mutableSetOf()

    fun addQueue(objectId: Long) {
        queue.add(objectId)
    }

    fun getById(objectId: Int): ProfileInterface? {
        return list.firstOrNull { it.id == objectId }
    }

    fun update(useProfiles: StateInstance<Int>) {
        val (_, setProfiles) = useProfiles

        val callback = ExecuteCallback { response, error ->
            if(response != null) {
                if(response is JsonObject) {
                    response.jsonObject["groups"]!!.jsonArray.map { it.jsonObject }.forEach {
                        println(it.toString())
                        list += GroupData(
                            id = -it["id"]!!.jsonPrimitive.int,
                            name = it["name"]!!.jsonPrimitive.content,
                            photo_50 = it["photo_50"]!!.jsonPrimitive.content,
                        )
                    }
                } else {
                    response.jsonArray.map { it.jsonObject }.forEach {
                        list += UserData(
                            id = it["id"]!!.jsonPrimitive.int,
                            first_name = it["first_name"]!!.jsonPrimitive.content,
                            last_name = it["last_name"]!!.jsonPrimitive.content,
                            photo_50 = it["photo_50"]!!.jsonPrimitive.content,
                        )
                    }
                }
                setProfiles(list.hashCode())
            } else if(error != null) {
                Snackbar("error", error.message)
            }
        }
        val userIds = queue.filter { it > 0 }.take(100)
        if(userIds.isNotEmpty()) {
            queue.removeAll(userIds.toSet())

            val params = mapOf(
                "fields" to "photo_50",
                "user_ids" to userIds.joinToString(","),
                "v" to versionApiVK
            )
            CallAsync("users.get", params, callback).submit()
        }
        val groupsIds = queue.filter { it < 0 }.take(500)
        if(groupsIds.isNotEmpty()) {
            queue.removeAll(groupsIds.toSet())

            val params = mapOf(
                "fields" to "photo_50",
                "group_ids" to groupsIds.map { -it }.joinToString(","),
                "v" to versionApiVK
            )
            CallAsync("groups.getById", params, callback).submit()
        }
    }
}