package su.exbot.forms.elements

import js.objects.jso
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.get
import org.w3c.dom.set
import react.FC
import react.dom.aria.ariaControls
import react.dom.aria.ariaExpanded
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.nav
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.dom.svg.ReactSVG.symbol
import react.dom.svg.ReactSVG.use
import react.router.Outlet
import react.router.dom.Link
import react.useState
import su.exbot.forms.dataBsTarget
import su.exbot.forms.dataBsToggle
import su.exbot.forms.projectName
import su.exbot.forms.setTheme
import web.cssom.ClassName
import web.cssom.None
import web.html.ButtonType

val navbar = FC {
    nav {
        className = ClassName("navbar navbar-expand-lg navbar-dark bg-dark")
        div {
            className = ClassName("container-fluid")
            Link {
                className = ClassName("navbar-brand")
                to = "/"
                +"$projectName (RADMIR)"
            }
            button {
                className = ClassName("navbar-toggler")
                type = ButtonType.button
                dataBsToggle = "collapse"
                dataBsTarget = "#navbarSupportedContent"
                ariaControls = "navbarSupportedContent"
                ariaExpanded = false
                ariaLabel = "Toggle navigation"
                span {
                    className = ClassName("navbar-toggler-icon")
                }
            }
            div {
                className = ClassName("collapse navbar-collapse")
                id = "navbarSupportedContent"
                ul {
                    className = ClassName("navbar-nav me-auto mb-2 mb-lg-0")
                }
                div {
                    className = ClassName("navbar-nav form-inline align-items-center")
                    li {
                        className = ClassName("nav-item dropdown")
                        svg {
                            xmlns = "http://www.w3.org/2000/svg"
                            style = jso {
                                display = None.none
                            }
                            symbol {
                                id = "circle-half"
                                viewBox = "0 0 16 16"
                                path {
                                   d = "M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"
                                }
                            }
                            symbol {
                                id = "moon-stars-fill"
                                viewBox = "0 0 16 16"
                                path {
                                    d = "M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"
                                }
                                path {
                                    d = "M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"
                                }
                            }
                            symbol {
                                id = "sun-fill"
                                viewBox = "0 0 16 16"
                                path {
                                    d = "M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
                                }
                            }
                        }
                        var currentTheme by useState(localStorage["theme"])
                        a {
                            className = ClassName("nav-link")
                            svg {
                                width = 20.0
                                height = 20.0
                                fill = "currentcolor"
                                use {
                                    href = if (currentTheme == "dark") "#moon-stars-fill" else "#sun-fill"
                                }
                            }
                            span {
                                className = ClassName("d-lg-none ms-2")
                                +"Изменить тему"
                            }
                            onClick = {
                                localStorage["theme"] = setTheme()
                                currentTheme = localStorage["theme"]
                            }
                        }
                    }
                    div {
                        className = ClassName("nav-item py-2 py-lg-1 col-12 col-lg-auto")
                        div {
                            className = ClassName("vr d-none d-lg-flex h-100 mx-lg-2 text-white")
                        }
                    }
                    img {
                        id = "profile-photo"
                        className = ClassName("rounded-circle")
                        src = localStorage["photo"]
                        width = 25.0
                        height = 25.0
                    }
                    a {
                        id = "profile-name"
                        className = ClassName("nav-link")
                        +localStorage["name"]
                        onClick = {
                            localStorage.clear()
                            window.location.href = window.location.origin
                        }
                    }
                }
            }
        }
    }
    Outlet()
}