package su.exbot.forms.model

import kotlinx.serialization.Serializable
import su.exbot.forms.model.enums.Permissions

@Serializable
data class AdminData(
    val formId: Int,
    val level: Int,
    val permissions: List<Permissions>
) {
    fun has(permission: Permissions): Boolean {
        return this.permissions.any { it == permission }
    }
}