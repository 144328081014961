package su.exbot.forms.routes.form

import kotlinx.browser.document
import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.router.useNavigate
import react.router.useParams
import react.useEffect
import react.useEffectOnce
import react.useState
import su.exbot.forms.client
import su.exbot.forms.elements.Icons
import su.exbot.forms.profiles.ProfileData
import su.exbot.forms.projectName
import su.exbot.forms.routes.comments.model.CommentEntity
import su.exbot.forms.routes.form.domain.CreateForm
import su.exbot.forms.routes.form.model.Form
import su.exbot.forms.routes.form.model.Item
import su.exbot.forms.containers.placeholder.PlaceholderContainer
import web.cssom.ClassName

object RequestRoute {

    fun element() = FC {
        val userFormId = useParams()["formId"] ?: return@FC
        val navigate = useNavigate()

        val contentItems = useState<MutableList<Item>?>(null)
        val useProfiles = useState(ProfileData.list.hashCode())
        val useComments = useState<Array<CommentEntity>>(arrayOf())

        var form: Form? by useState()

        document.title = "$projectName | Форма №$userFormId"

        val (items, setItems) = contentItems

        useEffect {
            if(ProfileData.queue.isNotEmpty()) {
                ProfileData.update(useProfiles)
            }
        }
        if(items != null) {
            div {
                className = ClassName("form-container")

                CreateForm {
                    this.contentItems = contentItems
                    this.useComments = useComments
                    this.form = form
                    this.formUserId = userFormId.toInt()
                }

                div {
                    button {
                        className = ClassName("btn btn-dark")
                        Icons.caretLeftFill()
                        +" Назад"
                        onClick = {
                            navigate(-1)
                        }
                    }
                }
            }
        } else {
            PlaceholderContainer {
                this.title = 50
                this.board = 200
                this.formMin = 80
                this.formMax = 100
                this.points = 5
            }
        }
        useEffectOnce {
            client.get<dynamic>("/backend/user-forms/$userFormId?expanded=true").then {
                if(it.status == 200) {
                    form = it.data["form"].unsafeCast<Form>()

                    val elements = mutableListOf<Item>()
                    for(row in it.data["items"]) {
                        elements.add(row.unsafeCast<Item>())
                    }
                    setItems(elements)
                }
            }
            client.get<Array<CommentEntity>>("/backend/user-forms/$userFormId/comments").then {
                if(it.status == 200) {
                    val (_, setComments) = useComments
                    setComments(it.data)
                }
            }
        }
    }
}