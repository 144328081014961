package su.exbot.forms.routes.main.domain

import emotion.react.css
import kotlinx.browser.document
import kotlinx.browser.window
import react.FC
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h5
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import su.exbot.forms.focus
import su.exbot.forms.routes.main.props.CompletedProps
import su.exbot.forms.select
import web.cssom.ClassName
import web.cssom.Color
import web.window.WindowTarget

val FormCompleted = FC<CompletedProps> { props ->
    val form = props.formData
    div {
        className = ClassName("form-container")
        div {
            className = ClassName("group")
            h5 {
                +form.name
            }
        }
        div {
            p {
                +"Поздравляем с завершением прохождения формы "
                span {
                    className = ClassName("fw-semibold")
                    +"\"${form.name}\""
                }
                br()
                br()
                +"Была сформирована "
                span {
                    className = ClassName("fw-semibold")
                    +"индивидуальная ссылка"
                }
                +", которая позволит старшей администрации оценить Вас и ваши знания."
                br()
                +"Убедительная просьба направить данную ссылку "
                if(form.profile != null) {
                    val profile = form.profile
                    a {
                        css(ClassName("fw-semibold")) {
                            color = Color("var(--bs-body-color)")
                        }
                        target = WindowTarget._blank
                        href = "https://vk.me/id${profile.id}"
                        +"${profile.first_name} ${profile.last_name}"
                    }
                    +" в личные сообщения и ждать ответа."
                } else if(form.group != null) {
                    +"в группу "
                    val group = form.group
                    a {
                        css(ClassName("fw-semibold")) {
                            color = Color("var(--bs-body-color)")
                        }
                        target = WindowTarget._blank
                        href = "https://vk.me/club${group.id}"
                        +group.name
                    }
                    +" в личные сообщения и ждать ответа."
                } else {
                    span {
                        className = ClassName("fw-semibold")
                        +"старшему администратору"
                    }
                    +", который поделился с Вами данной формой."
                }
                br()
                br()
                +"Для копирования, нажмите комбинацию клавиш "
                span {
                    className = ClassName("fw-semibold")
                    +"Ctrl + C"
                }
                +" или нажмите на кнопку с иконкой "
                i {
                    className = ClassName("bi bi-clipboard-fill")
                }
            }
            div {
                className = ClassName("input-group")
                input {
                    className = ClassName("form-control token")
                    readOnly = true
                    value = "${window.location.origin}/request?id=${form.userFormId}"
                }
                button {
                    className = ClassName("btn btn-dark")
                    i {
                        className = ClassName("bi bi-clipboard-fill")
                    }
                    onClick = {
                        val query = document.querySelector(".token")
                        if (query != null) {
                            query.focus()
                            query.select()
                            document.execCommand("copy")
                        }
                    }
                }
            }
        }
    }
}