package su.exbot.forms.routes.comments.model

@OptIn(ExperimentalJsExport::class)
@JsExport
data class CommentEntity(
    val id: Int,
    val fromId: Int,
    val dateAtHuman: String,
    val text: String,
    val type: Int,
    val canDelete: Boolean,
    val canEdit: Boolean,
    var edited: Boolean
)