package su.exbot.forms.containers.panel.tabs.configuring.model

import kotlinx.serialization.Serializable

@Serializable
data class FieldEntity(
    val id: Int,
    val group: String,
    val questions: List<Int>,
    val numberQuestions: Int,
    val page: Int,
)
