package su.exbot.forms.routes.main.domain.input

import react.ForwardRef
import react.dom.html.ReactHTML.input
import react.useImperativeHandle
import react.useRef
import su.exbot.forms.FocusableElement
import su.exbot.forms.routes.main.props.InputRefProps
import web.cssom.ClassName
import web.html.HTMLInputElement

val FormInput = ForwardRef<_, InputRefProps> { props ->
    val item = props.item
    val inputRef = useRef<HTMLInputElement>()

    useImperativeHandle(props.ref, inputRef) {
        inputRef.current?.let(::FocusableElement)
    }

    input {
        className = ClassName("form-control")
        disabled = item.edited != true || item.edited == null
        placeholder = "Введите текст и нажмите кнопку справа"
        maxLength = item.limit
        defaultValue = item.answer
        onInput = {
            item.answer = it.currentTarget.value
        }
        ref = inputRef
    }
}