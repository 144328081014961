package su.exbot.forms.routes.main.domain

import emotion.react.css
import generated.Snackbar
import generated.snackBar
import js.objects.jso
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.router.dom.Form
import su.exbot.forms.Focusable
import su.exbot.forms.client
import su.exbot.forms.functions.TextFormat
import su.exbot.forms.routes.main.domain.input.FormInput
import su.exbot.forms.routes.main.domain.input.FormTextArea
import su.exbot.forms.routes.main.model.Item
import su.exbot.forms.routes.main.props.RawItemProps
import web.cssom.ClassName
import web.cssom.FontStyle
import web.cssom.FontWeight
import web.cssom.px
import kotlin.js.json

val TableRow = FC<RawItemProps> { props ->
    val data = props.data

    val (isWaitingMode, setWaitingMode) = useState(false)
    useEffect {
        if(isWaitingMode && data.edited != true) {
            setWaitingMode(false)
        }
    }

    Form {
        className = ClassName("question")
        p {
            css(ClassName("text-start")) {
                marginBottom = 6.px
            }
            span {
                css {
                    fontWeight = FontWeight.bold
                }
                +"Вопрос №${data.index}: "
            }
            +data.name
        }
        if(!data.text.isNullOrBlank()) {
            div {
                css {
                    fontSize = 15.px
                    fontStyle = FontStyle.italic
                    marginBottom = 6.px
                }
                TextFormat {
                    this.text = data.text
                }
            }
        }
        val inputRef = useRef<Focusable>()
        useEffect {
            if(data.edited == true) {
                inputRef.current?.focus()
            }
        }

        if(data.rows == 1) {
            div {
                className = ClassName("input-group")
                FormInput {
                    this.ref = inputRef
                    this.item = data
                }
                button {
                    className = ClassName("btn btn-dark")
                    if(data.edited != true || data.edited == null) {
                        i {
                            className = ClassName("bi bi-pencil-fill")
                        }
                    } else if(isWaitingMode) {
                        disabled = true
                        span {
                            className = ClassName("spinner-border spinner-border-sm")
                        }
                    }  else {
                        i {
                            className = ClassName("bi bi-send-check-fill")
                        }
                    }
                }
            }
        } else {
            FormTextArea {
                this.ref = inputRef
                this.item = data
            }
            button {
                className = ClassName("btn btn-dark mt-2")
                if(data.edited != true || data.edited == null) {
                    i {
                        className = ClassName("bi bi-pencil-fill")
                    }
                    +" Редактировать ответ"
                } else if(isWaitingMode) {
                    disabled = true
                    span {
                        className = ClassName("spinner-border spinner-border-sm")
                    }
                } else {
                    i {
                        className = ClassName("bi bi-send-check-fill")
                    }
                    +" Сохранить ответ"
                }
            }
        }

        onSubmit = {
            if(data.edited == true) {
                if(data.answer.isBlank()) {
                    Snackbar("error", "Вы не дали ответ на вопрос №${data.index}")
                } else {
                    setWaitingMode(true)

                    val formData = props.table.formData
                    client.patch<dynamic>(
                        url = "/backend/user-forms/${formData.userFormId}/answers/${data.id}",
                        data = json("text" to data.answer)
                    ).then {
                        if(it.status == 200) {
                            data.answer = it.data["text"].toString()
                            data.sent = true

                            changeEditedItems(props.table.contentItems, data)
                        } else {
                            setWaitingMode(false)
                        }
                    }
                }
            } else {
                val (items, _) = props.table.contentItems
                if(items.none { it.edited == true }) {
                    changeEditedItems(props.table.contentItems, data)
                } else {
                    snackBar(jso {
                        status = "error"
                        message = "Вы не можете активировать строку редактирования, т.к у Вас есть активная строка"
                        width = 400.px
                        fixed = true
                    })
                }
            }
        }
    }
}

private fun changeEditedItems(contentItems: StateInstance<MutableList<Item>>, data: Item) {
    val (items, setItems) = contentItems

    val rows = mutableListOf<Item>()
    for(item in items) {
        if(item.index == data.index) {
            data.edited = data.edited != true
            rows.add(Item(data))

            if(data.edited != true) {
                items.firstOrNull {
                    it.index > data.index && (it.answer.isBlank())
                }?.edited = true
            }
        } else rows.add(item)
    }
    setItems(rows)
}