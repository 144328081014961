package su.exbot.forms.containers.panel.tabs.administrators

import js.objects.jso
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import react.router.useParams
import react.useEffect
import react.useEffectOnce
import react.useState
import su.exbot.forms.adminRights
import su.exbot.forms.client
import su.exbot.forms.getByFormId
import su.exbot.forms.model.enums.Permissions
import su.exbot.forms.profiles.ProfileData
import su.exbot.forms.containers.panel.tabs.administrators.components.AdminComponent
import su.exbot.forms.containers.panel.tabs.administrators.data.AdminEntity
import web.cssom.ClassName
import web.cssom.pct
import web.html.ButtonType

val AdminsContainer = FC {
    val formId = useParams()["formId"] ?: return@FC

    val useProfiles = useState(ProfileData.list.hashCode())
    val useItems = useState<Array<AdminEntity>>(arrayOf())
    val (list, setList) = useItems

    useEffect {
        if(ProfileData.queue.isNotEmpty()) {
            ProfileData.update(useProfiles)
        }
    }
    useEffectOnce {
        client.get<dynamic>("/backend/admins/$formId").then {
            if(it.status == 200) {
                setList(it.data["items"].unsafeCast<Array<AdminEntity>>())
            }
        }
    }

    div {
        className = ClassName("row table-responsive px-4")
        table {
            className = ClassName("table")
            thead {
                tr {
                    className = ClassName("text-center")
                    th {
                        +"Назначен"
                    }
                    th {
                        +"Пользователь"
                    }
                    th {
                        +"Уровень"
                    }
                    th {
                        +"Выдал"
                    }
                    th {

                    }
                }
            }
            tbody {
                className = ClassName("text-nowrap")
                list.sortedWith(compareByDescending(AdminEntity::level)).forEach {
                    AdminComponent {
                        this.adminEntity = it
                        this.formId = formId.toInt()
                        this.useItems = useItems
                    }
                }
            }
        }
    }

    val (inputEnabled, setInputEnabled) = useState(false)
    val (inputProfile, setInputProfile) = useState("")
    val (selectedLevel, setSelectedLevel) = useState(1)

    val adm = adminRights.getByFormId(formId.toInt())
    if(adm != null && adm.has(Permissions.CAN_MODIFY_ADMIN)) {
        div {
            className = ClassName("row")
            if (inputEnabled) {
                div {
                    className = ClassName("input-group")
                    input {
                        className = ClassName("form-control form-control-sm")
                        placeholder = "Введите ссылку на профиль пользователя"
                        style = jso {
                            flexBasis = 70.pct
                        }
                        value = inputProfile
                        onChange = {
                            setInputProfile(it.target.value)
                        }
                    }
                    select {
                        className = ClassName("form-select form-select-sm")
                        value = selectedLevel
                        (1..5).forEach {
                            option {
                                value = it
                                +" – $it ур."
                            }
                        }
                        onChange = {
                            setSelectedLevel(it.target.value.toInt())
                        }
                    }
                }
            }
            button {
                className = ClassName("btn btn-dark btn-sm")
                type = ButtonType.button
                +"Добавить администратора"
                onClick = {
                    if (inputEnabled && inputProfile.isNotEmpty()) {
                        client.post<dynamic>(
                            url = "/backend/admins/${formId}",
                            data = buildJsonObject {
                                put("profile", inputProfile)
                                put("level", selectedLevel)
                            }.toString()
                        ).then {
                            if (it.status == 200) {
                                setList(list + it.data["item"].unsafeCast<AdminEntity>())

                                setInputProfile("")
                                setInputEnabled(false)
                            }
                        }
                    } else {
                        setInputEnabled(!inputEnabled)
                    }
                }
            }
        }
    }
}