package su.exbot.forms.routes.main

import emotion.react.css
import kotlinx.browser.document
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.decodeFromDynamic
import org.w3c.dom.url.URL
import react.FC
import react.StateInstance
import react.dom.html.ReactHTML.div
import react.router.useNavigate
import react.useState
import su.exbot.forms.adminRights
import su.exbot.forms.client
import su.exbot.forms.containers.placeholder.PlaceholderContainer
import su.exbot.forms.format
import su.exbot.forms.projectName
import su.exbot.forms.routes.main.domain.FormCompleted
import su.exbot.forms.routes.main.domain.createForm
import su.exbot.forms.routes.main.model.Form
import su.exbot.forms.routes.main.model.Item
import web.cssom.ClassName
import web.cssom.None
import web.location.location

object IndexRoute {
    private const val title = "Гл. Страница"

    @OptIn(ExperimentalSerializationApi::class)
    fun element() = FC {
        document.title = "$projectName | $title"

        val url = URL(location.href.replace("amp;", ""))
        val params = url.searchParams
        val state = params.get("state")

        val formData = useState<Form?>(null)
        val (form, setFormData) = formData
        val contentItems = useState(mutableListOf<Item>())
        val navigate = useNavigate()

        if(!state.isNullOrBlank() || form != null) {
            if(form != null) {
                document.title = "$projectName | ${form.name}"
            }
            /*params.delete("state")
            history.pushState(null, "", url.href)*/
            val (items, _) = contentItems
            if(form == null || items.isEmpty() && form.status != 1) {
                PlaceholderContainer {
                    this.title = 50
                    this.formMin = 85
                    this.formMax = 86
                    this.points = 7
                }
            }
            if(form == null) {
                val search = location.search.replace("amp;", "")

                client.get<dynamic>("/backend/user-forms/generate$search").then {
                    if(it.status == 200) {
                        setFormData(it.data.unsafeCast<Form>())
                    }
                }
            } else if(form.status == 1) {
                FormCompleted {
                    this.formData = form
                }
            } else if(form.page == null) {
                loadItemsPage(form, 1, contentItems)
            } else if(items.isNotEmpty()) {
                div {
                    css(ClassName("form-container")) {
                        userSelect = None.none
                    }
                    onCopy = {
                        it.clipboardData.setData("text/plain", "https://vk.com/exsite123")
                        it.preventDefault()
                    }
                    createForm {
                        this.currentPage = form.page ?:1
                        this.contentItems = contentItems
                        this.formData = formData
                    }
                }
            }
        } else {
            // Загрузка прав и если есть, то перенаправляем в панель
            client.get<dynamic>("/backend/admins").then {
                if(it.status == 200) {
                    adminRights = format.decodeFromDynamic(it.data)
                    if(adminRights.isNotEmpty()) {
                        navigate("/panel")
                    }
                }
            }
        }
    }

    fun loadItemsPage(formData: Form, page: Int, contentItems: StateInstance<MutableList<Item>>) {
        val (items, setItems) = contentItems
        if(items.isNotEmpty()) setItems(mutableListOf())

        client.get<dynamic>("/backend/user-forms/${formData.userFormId}?page=$page").then {
            if(it.status == 200) {
                val list = mutableListOf<Item>()
                for(row in it.data["items"]) {
                    val item = row.unsafeCast<Item>()
                    if(item.answer.isNotBlank()) {
                        item.sent = true
                    }
                    list.add(item)
                }
                list.firstOrNull { d -> d.answer.isBlank() }?.edited = true
                formData.page = it.data["page"] as Int
                setItems(list)
            }
        }
    }

}
